import { Component, OnInit ,Optional,Inject, ViewChild, TemplateRef} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AbstractBaseAddUpdateComponent } from '../../../../components/base/abstract-base-add-update-component';
import { IBaseService } from '../../../../services/ibase-service';
import { Team, TeamUser } from '../model/Team'
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as _ from 'lodash'
import { TeamServiceService } from '../team-service.service';
import { ActivatedRoute } from '@angular/router';
import { HostListener } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog ,MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA} from '@angular/material';
import { DataSharingServiceService } from '../../../../../../src/app/pages/data-sharing-service.service';
import { ServiceAreaServices } from '../../../../services/service_area/service_area.service';
import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationReportService } from '../../../../pages/reports/communication-report/communication-report.service';

export interface DialogPassData {
  id: number;
}


@Component({
  selector: 'app-add-update-teams',
  templateUrl: './add-update-teams.component.html',
  styleUrls: ['./add-update-teams.component.scss']
})
export class AddUpdateTeamsComponent extends AbstractBaseAddUpdateComponent<Team> implements OnInit {
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('caseactionBtn', { static: false }) caseactionBtnRef: TemplateRef<any>;
  @ViewChild('isassignable',{ static: false }) isassignableRef: TemplateRef<any>;
  @ViewChild('status', { static: false }) statusRef: TemplateRef<any>;
  team = new Team();
  form: FormGroup;
  fcsubmitted:boolean=false
  staffform: FormGroup;
  caseReasonform: FormGroup;
  statusList = [
    { name: 'Active', value: 'Active' },
    { name: 'Inactive', value: 'In Active' },
  ]
  serviceAreaList = { id: -1, name: 'All'}
  teamTypeList = { text: 'Back Office', value: 'Back Office' }
  serviceAreaId: any= [];
  teamUser = new TeamUser()
  typeOfTeam: any= [];
  caseReasonList=[]
  staffGridSettings: any = {};
  allColumns: any[] = [];
  caseReasonGridSettings: any = {};
  caseReasonallColumns: any[] = [];
  tableKeyEnum = TableKeyEnum;
  dataSource = new MatTableDataSource([]);
  caseReasondataSource = new MatTableDataSource([]);
  staffList: [];
  DataList: any;
  partnerid: string;
  isDisabled:boolean=false;
  addNewClicked:boolean = false;
  url:string;
  groupList:any[]=[];
  tempteamCaseReasonsMapping=[];
  constructor(
    public toastservice: ToastrService,
    public router: Router,
    private teamservice: TeamServiceService,
    private activatedRoute: ActivatedRoute,
    private genericDataSharingService: DataSharingServiceService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<AddUpdateTeamsComponent>,
    private serviceAria: ServiceAreaServices,
    private commonListService: CommonListServicesService,
    private tosterservice: ToastrService,
    public modalService: NgbModal,
    private CommunicationReportService: CommunicationReportService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
  ) {
    super(toastservice, router)
  }
  teamId:number
  ngOnInit() {
    this.init();
    this.setGridSetting();
    this.caseReasonsetGridSetting();
    // if(this.data.id){
    //   this.isDisabled=true;
    //   this.getById(this.data.id)
    // }

    let id = this.activatedRoute.snapshot.paramMap.get('id');
    this.teamId = Number(id);
    if (id) {
      this.isUpdateComponent = true;
      this.getById(Number(id));
    }
    setTimeout(() => {
      this.getserviceAreaList();
      this.getTeamTypeList();
    }, 150);
    this.partnerid = localStorage.partnerId;
    
    this.getGroupList();
    this.getCaseReason()
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'isAssignable') {
        col.customControls = [this.isassignableRef];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
    });
    this.caseReasonallColumns.forEach((col) => {
      if(col.colKey=='status'){
        col.customControls=[this.statusRef]
      }
      if(col.colKey=='actionCol'){
        col.customControls=[this.caseactionBtnRef]
      }
    });
  }

  // getStaffList(partnerid) {
  //   this.CommunicationReportService.staffuserByPartner(partnerid).subscribe(result => {
  //   // let tempdata =  this.team.teamUserMapping.map(res=>res.staffId)
  //   // //console.log(this.team.teamUserMapping)
  //   // tempdata.forEach(res=>{
  //   //   let index = result.staffUserlist.findIndex(ls=> ls.id == res)
  //   //   result.staffUserlist.splice(index,1);
  //   // }) 
  //   this.staffList = Object.assign([],result.staffUserlist);
  //   })
  // }

  getStaffByServiceArea(){
    //console.log('res', this.form.get('serviceAreaIdList').value)
    let data = {
      "serviceAreaIdList": this.form.get('serviceAreaIdList').value,
    } 
    this.teamservice.getStaffByServiceArea(data).subscribe((res)=>{
      this.staffList = Object.assign([],res.data);
    })
  }
  getCaseReason(){
    this.teamservice.getcaseReason().subscribe((res)=>{
      this.caseReasonList = Object.assign([],res.dataList);
    })
  }
  getserviceAreaList(){
    this.serviceAria.getServiceAreaList().subscribe((res) => {
      this.serviceAreaId = res.dataList;
      if(Number(this.team.serviceAreaIdList) == -1){
        this.serviceAreaId.push(this.serviceAreaList);
      }
    })
  }

  getTeamTypeList(){
    this.commonListService.getGenericCommonList('teamType').subscribe((res)=>{
      this.typeOfTeam = res.dataList;
      if(this.team.typeOfTeam == 'Back Office'){
        this.typeOfTeam.push(this.teamTypeList);
      }
    })
  }



  getService(): IBaseService<Team> {
    return this.teamservice;
  }
  setData(data: Team) {
    this.team = data;
    if(this.team){
      if(this.team.teamUserMapping && this.team.teamUserMapping.length>0){
        this.refreshDataSource(this.team.teamUserMapping);
      }
      if(this.team.teamCaseReasonsMapping){
        this.caseReasonrefreshDataSource(this.team.teamCaseReasonsMapping);
      }
    }

    let body = {
      "serviceAreaIdList": this.team.serviceAreaIdList
    } 

    this.teamservice.getStaffByServiceArea(body).subscribe((res)=>{
      this.staffList = Object.assign([],res.data);
    })
    // setTimeout(() => {
    //   //this.getStaffList(this.partnerid);
    //   this.getStaffByServiceArea();
    // }, 380);
  }
  getData(): Team {
    return this.team;
  }
  
  getModuleHomeUrl() {
    if(this.isUpdateComponent){
      return '/hr/add-team/'+ this.teamId ;
    }else{
      let id = this.responseData.data.id;
      return '/hr/add-team/'+ id ;
    }
    }

  getActiveRoute() {
    return this.activatedRoute;
  }
  getNewModal() {
    return new Team;
  }
  getFormGroup(): FormGroup {
    return this.form;
  }
  setValidation() {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required),
      serviceAreaIdList: new FormControl([],Validators.required),
      typeOfTeam: new FormControl('',Validators.required),
      groupid:new FormControl('',Validators.required)
    })

    this.staffform = new FormGroup({
      empCode:new FormControl('')
    })
    this.caseReasonform = new FormGroup({
      caseReason:new FormControl(null)
    })
  }
  get f() {
    return this.form.controls;
  }
  get fc() {
    return this.caseReasonform.controls;
  }
  formSubmit() {
    if (!this.form.valid) {
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    } 
    else {
      this.onSubmit();
    }
  }

  onDelete(event) {
      let self = this;
      if (event) {
        self.delete(event);
      }
  }
  onDeletecaseReason(event) {
    let self = this;
    if (event) {
      self.caseReasondelete(event);
    }
}
  onSaveStaff(type?){
    if(this.team.teamCaseReasonsMapping.length>0){
      if(type=='caseReason'){
        if(this.tempteamCaseReasonsMapping.length==this.team.teamCaseReasonsMapping.length){
          this.tosterservice.error("Please Select New Case Reason!");
          return
        }
      }
      this.addNewClicked = false;
      this.onUpdate();
      this.modalService.dismissAll();
      setTimeout(()=>{
        this.getById(this.teamId)
      },100)
      //this.getStaffList(this.partnerid);
      this.getStaffByServiceArea();
      this.staffform.get('empCode').reset();
    }
   else{
    this.tosterservice.error("atleast Select one Case reason");
   }
    
  }

  // onDeletSelected(i:number) {
  //   this.team.teamUserMapping.splice(i,1);
  // }

  delete(event) {
    let self = this;
      let obj ={
        id : event.id,
        isAssignable : event.isAssignable,
        staffId : event.staffId,
        teamId : event.teamId
      }
      this.teamservice.deleteStaff(obj).subscribe((data: any) => {
        if (data.responseCode === 200) {
          this.tosterservice.success('Staff Detail deleted successfully');
          this.isUpdateComponent = true;
          let index = this.team.teamUserMapping.findIndex(res=>res.staffId == obj.staffId)
          this.team.teamUserMapping.splice(index,1)
          //this.getStaffList(this.partnerid);
          this.getStaffByServiceArea();
          this.refreshDataSource(this.team.teamUserMapping);
        }
        else {
          this.tosterservice.error(data.responseMessage);
        }
      }, err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      });
  }
  caseReasondelete(event) {
    let self = this;
      this.teamservice.deletecaseReason(event).subscribe((data: any) => {
        if (data.responseCode === 200) {
          this.tosterservice.success('Case Reason Detail deleted successfully');
          this.getById(this.teamId)
        }
        else {
          this.tosterservice.error(data.responseMessage);
        }
      });
  }

  onAddStaffClick(staffmodal){
    this.modalService.open(staffmodal, { ariaLabelledBy: 'modal-basic-title', size: 'xl',backdrop:'static' })
    this.addNewClicked = true;
  }
  onAddReasonClick(caseReason){
    this.modalService.open(caseReason, { ariaLabelledBy: 'modal-basic-title', size: 'xl',backdrop:'static' })
    this.getCaseReason()
    this.tempteamCaseReasonsMapping=_.cloneDeep(this.team.teamCaseReasonsMapping)
  }
  onPopUpCancel(){
    this.modalService.dismissAll();
    this.addNewClicked = false;
    this.staffform.get('empCode').reset();
  }
  onPopUpCancelcaseReason(){
    this.modalService.dismissAll();
    this.team.teamCaseReasonsMapping=_.cloneDeep(this.tempteamCaseReasonsMapping)
    this.getCaseReason()
  }
  onChangeServiceArea(){
    this.getStaffByServiceArea();
  }

  onChangeStaff(event) {
    this.staffform.get('empCode').reset();
      if(event){
        this.teamUser = new TeamUser()
        this.teamUser.staffName = event.firstname;
        this.teamUser.empCode = event.empCode;
        this.staffform.get('empCode').setValue(this.teamUser.empCode);
        this.teamUser.staffId = event.id;
        this.teamUser.isAssignable = false;
        this.teamUser.teamId = this.teamId;
        if(this.team.teamUserMapping && this.team.teamUserMapping.length > 0){
        let temp :any = {}
         temp = this.team.teamUserMapping.find(res=> res.staffId === this.teamUser.staffId)
         if(temp){
          if(temp.staffId === this.teamUser.staffId){
            this.tosterservice.error('staff already exist')
          }
          else{
            this.team.teamUserMapping.push(this.teamUser)
          }
         }
         else{   
          this.team.teamUserMapping.push(this.teamUser)
         }
        }else{
          this.team.teamUserMapping.push(this.teamUser)
        }
      }
    }
    
  onToggle(event,data){
    let index = this.team.teamUserMapping.findIndex(res=>res.staffId == data.staffId)
    this.team.teamUserMapping[index].isAssignable = event.checked;
    if(this.addNewClicked == false){
      this.onUpdate();
      setTimeout(()=>{
        this.getById(this.teamId)
      },100)
    }
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
  caseReasonrefreshDataSource(data) {
    if (data) {
      this.caseReasondataSource= new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
  setGridSetting() {
    let self = this;
    this.allColumns = [
      { colKey: 'index', 
        default: 'ID', 
        rowKey: 'id', 
        width: '5%', 
        isFilterAllow: false, 
        isNumber: true 
      },
      { colKey: 'staffName', 
        default: 'Staff Name', 
        rowKey: 'staffName', 
        width: '30%', 
        isNumber: false, 
        isFilterAllow: false, 
        enableEdit: true, 
        enableDelete: true, 
        enableSort: true 
      },
      { colKey: 'empCode', 
      default: 'Employee Code', 
      rowKey: 'empCode', 
      width: '30%', 
      isNumber: false, 
      isFilterAllow: false, 
      enableEdit: true, 
      enableDelete: true, 
      enableSort: true 
    },
      {
        colKey: 'isAssignable',
        default: 'Is Assignable',
        rowKey: 'isAssignable',
        width: '20%',
        customControls: [this.isassignableRef],
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '5%',
        isStickyEnd: true
      }
    ];

    this.staffGridSettings = {
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum,
      perPage: 5,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      //deleteMsgBoxTitle: 'Are you sure to delete this Detail ?',
      //deleteMsgBoxDetail: '"Slot Details Name :${name} " '
    };
  }
  caseReasonsetGridSetting() {
    let self = this;
    this.caseReasonallColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '1%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'name',
        default: 'Name',
        rowKey: 'name',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'caseReasonType',
        default: 'Case Reason Type',
        rowKey: 'caseReasonType',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'Status',
        rowKey: 'status',
        customControls: [this.statusRef],
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];

    this.caseReasonGridSettings = {
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum,
      perPage: 5,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.caseReasonallColumns,
    };
  }
  getId(): number {
    return this.team.id;
  }
  backToViewList() {
    this.router.navigate(['/hr/team-list']);
  }
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }
  closeDialog(){
    this.dialogRef.close();
    }

 getGenericDataService(){
      return this.genericDataSharingService
  }
      
getGroupList(){
  this.teamservice.getGroupList().subscribe((res)=>{
      if(res.responseCode==200){
        this.groupList=res.dataList;
      }else{
        this.toastservice.error(res.responseCode)
      }
    })
}
onChangeCase(event){
  if(event){
    let obj={
      "reasonId": event.reasonId,
      "name": event.name,
      "status": event.status,
      "caseReasonType": event.caseReasonType,
      "etr": event.etr,
      "etrUnit": event.etrUnit,
      "isDeleted": false,
      "teamId": this.teamId,
    }
    this.team.teamCaseReasonsMapping.push(obj)
    this.caseReasonform.get('caseReason').reset()
    
  }
}
onOpencaseReason(){
  this.caseReasonList=this.caseReasonList.filter((res)=>!this.team.teamCaseReasonsMapping.some((ls)=>ls.name===res.name))
}
}
