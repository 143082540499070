import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class ApplyChargeService {
  constructor(private http: HttpClient) {

  }


  getSubscriberCharges(): Observable<any> {
    const get_url = BASE_API_URL + 'subscriber/getSubscriberCharges';
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getCharges(type): Observable<any> {
    const get_url = BASE_API_URL + 'lead/customer/operation/getLeadChargesByType/'+type;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  getchargeTypeList(): Observable<any> {
    const get_url = BASE_API_URL + 'commonList/generic/leadChargeType';
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  getPaymentModeListFromGenericApi(): Observable<any> {
    const get_url = BASE_API_URL + 'commonList/generic/paymentMode';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }



  updateApplyCharge(apply_charge_data): Observable<any> {
    const get_url = BASE_API_URL + 'subscriber/applyCharge';
    return this.http.post<any>(get_url,apply_charge_data,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getTaxByAmountOnChecked(obj){
    const post_url = BASE_API_URL + 'reverseTaxCalculation/ByAmount';
    return this.http.post<any>(post_url,obj)
  }

  getPaymentCollectorList(custid): Observable<any> {
    const get_url = BASE_API_URL + 'getStaffByRoleAndServiceArea/' + custid;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
  updateApplyLeadCharge(data): Observable<any> {
    const get_url = BASE_API_URL + 'lead/customer/operation/addCharge';
    return this.http.post<any>(get_url,data,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
}
