import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { setInterval } from 'timers';
import { interval, observable } from 'rxjs';
import _ from 'underscore';
import { getParsedCommandLineOfConfigFile } from 'typescript';
import { sharedStylesheetJitUrl } from '@angular/compiler';
import { repeat  } from 'rxjs/operators';
import { of } from 'rxjs';
import { numberValidation } from '../validation/numberValidation';
import {  debounce } from 'rxjs/operators';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';


@Component({
  selector: 'app-search-autocomplete',
  templateUrl: './search-autocomplete.component.html',
  styleUrls: ['./search-autocomplete.component.scss']
})
export class SearchAutocompleteComponent implements OnInit {

  @Input()
  @ViewChild('autocomplete',{static:false}) autocomplete:NgSelectComponent; 
  isClear: any

  @Input()
  api: any
  @Input()
  fields:any
  @Input()
  disableflag:boolean;
  @Input()
  charLength:number;
  @Output() public selectedId : EventEmitter<any> = new EventEmitter<any>();;
  public search: string;
  public searchtype:any;
  public dataList: any;
  temp:any;
  private _default_value;
  get default_value(): any {
    return this._default_value;
  }

  @Input()
  set default_value(val: any) {
    
    this._default_value = val;
    if (this._default_value) {
      this.searchtype = this._default_value;
      this.getlist({term:this.searchtype},true);
    }else{
      this.searchtype =null;
      this.dataList=[];
    }
  }
  timer:any;
  stopUrl:any;
  stopUrlflag:boolean=true;
  constructor(public http: HttpClient) { }

  ngOnInit() {

  if(!this.charLength){
    this.charLength = 3
  }
  
  if (this._default_value) {
      this.searchtype = this._default_value;
      this.getlist({term:this.searchtype},true); 
  }
  }
 
  public getlist(search,default_value?:any) {
    if(search.term && search.term.length>3){
      
      this.temp = search
    }
    
  if (search.term && search.term.length>this.charLength && this.stopUrlflag === true) {
   this.stopUrlflag=false;
   if(!default_value){
    setTimeout(() => {
      this.getAPICall(this.temp,default_value)   
    }, 700);
   }
   else{
    this.getAPICall(this.temp,default_value)   
   }
 
  }
  else{
    this.temp=search;
  }
  }

  reset(){
    this.searchtype = null;
  }
  getAPICall(search,default_value?:any){
    if(this.api && search){
    const get_url = BASE_API_URL + this.api + search.term.trim();
      
    this.http.get<any>(get_url).subscribe(Response => {
      
      this.stopUrlflag=true;
      if(Response.dataList){
        this.dataList = Response.dataList.map((o) => {
          let abc = Object.values(o);
          let demo;
          abc.forEach((ls)=>{
            if(ls)
              demo = demo + ` ${ls}`
          });
  
          o.search_label = demo
  
          return o;
        }); 
        if (default_value) {
          let call_from_autocomplete = true;
          this.stopUrlflag=true;
          this.setData(this.dataList,call_from_autocomplete);
        }
      }
    

     
    })
  }
  

  }

   customSearchFn(term: string, item: any) {
    term = term.toLowerCase();

    // Creating and array of space saperated term and removinf the empty values using filter
    let splitTerm = term.split(' ').filter(t => t);

    let isWordThere = [];

    // Pushing True/False if match is found
    splitTerm.forEach(arr_term => {
      let search = item['search_label'].toLowerCase();
      isWordThere.push(search.indexOf(arr_term) != -1);
    });

    const all_words = (this_word) => this_word;
    // Every method will return true if all values are true in isWordThere.
    return isWordThere.every(all_words);
  }

  setData(event,call_from_autocomplete?:any) {
    this.selectedId.emit({event:event,call_from_autocomplete:call_from_autocomplete});
  }


}

